/**
 * Mixin to check user role or permission from spatie/laravel-permission
 */
import { usePage } from '@inertiajs/vue3';

const permissions = {
	methods: {
		$authenticated: authenticated,
		$role: role,
		$permission: permission,
	},
};

export default permissions;

// TODO: Make it a hook
export function authenticated() {
	return !!usePage().props?.auth?.user;
}

// TODO: Make it a hook
export function role(role) {
	const roles = usePage().props?.auth?.user?.roles;

	return roles?.includes(role);
}

// TODO: Make it a hook
export function permission(permission) {
	const permissions = usePage().props?.auth?.user?.permissions;

	return permissions?.includes(permission);
}
