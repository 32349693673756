/** Mixin to get localized string from spatie/laravel-translatable based on current locale */
import { usePage } from '@inertiajs/vue3';

// TODO: Make it a hook
export function localized(string) {
	const currentLocale = usePage().props?.locale;
	if (!currentLocale || !string) {
		// Current locale or string are not set
		return string || '';
	}
	if (string[currentLocale]) {
		return string[currentLocale];
	}
	// Fallback to first locale or provided string
	const firstLocale = Object.keys(string)[0];
	return string[firstLocale] ?? string;
}

const translatable = {
	methods: {
		$localized: localized,
	},
};

export default translatable;
